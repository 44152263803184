/* PrintResult.css */

@media print {
    .print-container {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 12pt;
        line-height: 1.5;
    }

    .PrintT {

        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 50px;
        font-weight: bold;
        height: 3260px;
        width: 2520px;






    }

   

    .container {
        page-break-after: always;
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    .col-lg-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .col-lg-3 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-lg-4 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .col-lg-1,
    .col-lg-2 {
        flex: 0 0 10%;
        max-width: 10%;
    }

    h6 {
        font-size: 10pt;
        margin: 0;
        padding: 0;
        font-weight: 700 !important;
        color: #212529;
    }

    h5 {
        font-size: 12px;
        color: #212529;

    }

    h2 {
        font-size: 14px;
        color: #212529;
    }

    .my-4,
    .my-2,
    .mx-1 {
        margin: 0;
        padding: 0;
    }
}